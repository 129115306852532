import {
  Backdrop,
  BadgeColor,
  Button,
  ClickAway,
  Icon,
  style,
  useToggle
} from '@do/walrus';
import React from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import MobileMenuItems from './MobileMenuItems';
import { CloseButtonContainer } from './styled-components';

const BUTTON_ICON_WIDTH = '1rem';

const Transition = styled(CSSTransition)`
  transition:
    transform ${style.vars.transitionSpeed.base} ease-out,
    opacity ${style.vars.transitionSpeed.base} ease-out;
  transform: translateX(0);
  z-index: 3;

  &&.dropdown-transition-exit-active,
  &.dropdown-transition-enter {
    opacity: 0;
    transform: translate(0, 50%);
  }

  &&.dropdown-transition-enter-active,
  &.dropdown-transition-enter-done,
  &.dropdown-transition-exit {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

const MenuFromBottom = styled.div`
  align-self: flex-end;
  width: 100%;
  height: 80vh;
  max-height: calc(50% - ${style.vars.space['8']});
  overflow: hidden;
  font-weight: ${style.vars.fontWeight.bold};
  text-align: left;
  pointer-events: auto;
  position: relative;
  background: ${style.colors.white};
  border: ${style.vars.borderWidth.thin} solid ${style.colors.shadesOfGrey['4']};
  border-top-left-radius: ${style.vars.borderRadius.base};
  border-top-right-radius: ${style.vars.borderRadius.base};
`;

const StyledButton = styled(Button)`
  background: transparent;
  border: 0 none;
  cursor: pointer;
  display: inline-flex;
  padding: 0;
  position: relative;
  height: 44px;
  width: 44px;
  flex-shrink: 0;
  margin-left: 6px;

  & img {
    display: block;
  }

  &:hover {
    background: transparent;
  }
`;

const MenuCloseIcon = styled(Icon)`
  width: ${BUTTON_ICON_WIDTH};
  height: ${BUTTON_ICON_WIDTH};
  color: ${style.colors.grey.primary};
  line-height: 1rem;
`;

const MenuCloseIconWrapper = styled(CloseButtonContainer)`
  right: 0;
  top: -1px;
`;

const MenuContainer = styled.div``;

export interface MenuItemProps {
  title: string;
  subtitle?: string;
  icon?: string;
  iconSize?: 'small' | 'large' | 'xlarge';
  iconXlinkHrefPrefix?: string;
  link?: string;
  badgeLabel?: string;
  badgeColor?: BadgeColor;
  isExternal?: boolean;
  children?: MenuItemProps[];
  customIcon?: React.ReactNode;
  ignoreCapitalization?: boolean;
  warning?: boolean;
}

interface MobileMenuProps {
  items: MenuItemProps[];
  className?: string;
  title?: string;
}

export const MobileMenu: React.FC<MobileMenuProps> = ({
  items,
  className,
  title = 'Menu'
}) => {
  const [isOpen, toggleOpen] = useToggle(false);
  const toggle = (isOpen) => () => toggleOpen(isOpen);

  return (
    <MenuContainer className={className}>
      <StyledButton
        variation="icon"
        title="Toggle menu"
        icon="top-nav-shell-hamburger"
        iconSize="xlarge"
        data-testid="default-trigger"
        onClick={toggle(true)}
      />
      {createPortal(
        <Transition
          in={isOpen}
          timeout={275}
          classNames="dropdown-transition"
          unmountOnExit
        >
          <Backdrop>
            <ClickAway mobile onClick={toggle(false)}>
              <MenuFromBottom>
                <MenuCloseIconWrapper onClick={toggle(false)}>
                  <MenuCloseIcon icon="close" />
                </MenuCloseIconWrapper>
                <MobileMenuItems items={items} isOpen={true} title={title} />
              </MenuFromBottom>
            </ClickAway>
          </Backdrop>
        </Transition>,
        document.body
      )}
    </MenuContainer>
  );
};
