import { Heading, Icon, focusOutline, mediaQueries, style } from '@do/walrus';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import styled from 'styled-components';
import { NavigationItem } from '../../types';
import copy from '../copy';
import { MobileMenu } from './MobileMenu';

const NavigationFlyoutMenu = dynamic(() => import('../NavigationFlyoutMenu'));

const CATEGORY_MENU_CLASSNAME = 'category-menu';
const USE_CASES_MENU_CLASSNAME = 'uses-cases-menu';
const FOR_VENDORS_MENU_CLASSNAME = 'for-vendors-menu';
const SWITCH_TO_MENU_CLASSNAME = 'switch-to-menu';
const MOBILE_MENU_CLASSNAME = 'mobile-menu';

const DOLogoLink = styled.a`
  height: 48px;
  width: 48px;
  overflow: hidden;
  padding: 9px;
  border-radius: 4px;
  background-color: #f3f5f9;
  color: ${style.colors.primary.base};
  transition:
    color 0.2s ease-out,
    background-color 0.2s ease-out;

  &:hover {
    background-color: ${style.colors.primary.base};
    color: white;
  }

  svg {
    height: 29px;
    width: 30px;
    vertical-align: top;
  }
`;

const DOLogoNav: React.FC = () => {
  return (
    <DOLogoLink href="https://www.digitalocean.com/" title="DigitalOcean">
      <Icon icon="logo" />
    </DOLogoLink>
  );
};

const MarketplaceLogoLink = styled.a`
  padding: 0 16px;
  color: #9aa1b1;
  transition:
    color 0.2s ease-out,
    background-color 0.2s ease-out;

  &:hover {
    color: ${style.colors.primary.base};
  }

  &:focus {
    ${focusOutline()}
  }

  svg {
    height: 24px;
    fill: currentColor;
  }
`;

function MarketplaceLogoNav() {
  return (
    <Link href="/" passHref prefetch={false}>
      <MarketplaceLogoLink title="DigitalOcean Marketplace">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 528.48 91.74">
          <path
            d="M12.72,22.3V72.22H.52V.37H16.41l20.91,56L58.23.37H74.12V72.22H61.82V22.3L43,72.22H31.68Z"
            transform="translate(-0.52 -0.37)"
          />
          <path
            d="M104.72,20.77c7,0,11.07,2.46,14.25,5.74V22h11.48V72.22H118.77V68.53a22,22,0,0,1-14,4.82c-14.45,0-24.5-11.17-24.5-26.24S90.27,20.77,104.72,20.77Zm.72,42.54c9,0,13.53-7.8,13.53-16.2s-4.51-15.68-13.53-15.68S92.11,38.7,92.11,47.11,96.42,63.31,105.44,63.31Z"
            transform="translate(-0.52 -0.37)"
          />
          <path
            d="M167.36,32.45a17,17,0,0,0-1.85-.1C153.73,32.35,150,39,150,52.54V72.22H138.35V22h11.48v5.43c3.9-4.72,8.61-6.15,15.27-6.15.31,0,1,0,2.26.1Z"
            transform="translate(-0.52 -0.37)"
          />
          <path
            d="M172.5.37h11.68v41.1L201.61,22h14.55L196.59,43.73l20.5,28.49H203.76l-15-20.4-4.62,5.23V72.22H172.5Z"
            transform="translate(-0.52 -0.37)"
          />
          <path
            d="M227.69,50.6c.62,7.58,5.74,12.19,13.22,12.19,6,0,10.05-2.87,12.92-7.07l7.89,7.38c-4.3,5.54-10.55,10.25-20.81,10.25-15.06,0-25.21-10.66-25.21-25.93,0-14.56,8.92-26.65,23.47-26.65,16.2,0,23,13,23,25.73,0,1.74-.1,4.1-.1,4.1Zm.62-9.33h21.42c-.1-4.41-2.67-9.84-10.46-9.84C232.71,31.43,229.23,36.45,228.31,41.27Z"
            transform="translate(-0.52 -0.37)"
          />
          <path
            d="M271.56,54.49v-22h-7.38V22h7.38V8h11.58V22h12V32.45h-12v20.3c0,7.27,1.64,9.12,8.2,9.12,1.13,0,3.79-.2,3.79-.2V72.12s-2.25.21-6.25.21C275.76,72.33,271.56,67.2,271.56,54.49Z"
            transform="translate(-0.52 -0.37)"
          />
          <path
            d="M327.33,73.35a21.67,21.67,0,0,1-14-4.82V92.11H301.61V22h11.48v4.51c3.07-3.28,7.17-5.74,14.24-5.74,14.46,0,24.5,11.27,24.5,26.34S341.79,73.35,327.33,73.35Zm-.82-10.25c8.92,0,13.43-7.48,13.43-15.89s-4.51-15.89-13.43-15.89-13.42,7.49-13.42,15.89S317.49,63.1,326.51,63.1Z"
            transform="translate(-0.52 -0.37)"
          />
          <path
            d="M359.09.37h11.69V72.22H359.09Z"
            transform="translate(-0.52 -0.37)"
          />
          <path
            d="M402.35,20.77c7,0,11.07,2.46,14.25,5.74V22h11.48V72.22H416.39V68.53a21.9,21.9,0,0,1-14,4.82c-14.46,0-24.5-11.17-24.5-26.24S387.89,20.77,402.35,20.77Zm.72,42.54c9,0,13.53-7.8,13.53-16.2s-4.51-15.68-13.53-15.68-13.33,7.27-13.33,15.68S394,63.31,403.07,63.31Z"
            transform="translate(-0.52 -0.37)"
          />
          <path
            d="M445.94,47.11c0,9.12,5.84,15.68,13.73,15.68,6.26,0,9.54-3.07,12.92-7.79l8,7.49c-4,5.84-11,10.86-21.32,10.86-13.83,0-25.21-11.17-25.21-26.24s11.38-26.34,25.21-26.34a24.32,24.32,0,0,1,20.4,10l-8.1,7.79c-3.17-4.2-6.66-7.17-12-7.17C451.68,31.43,445.94,38,445.94,47.11Z"
            transform="translate(-0.52 -0.37)"
          />
          <path
            d="M494.56,50.6c.61,7.58,5.74,12.19,13.22,12.19,6,0,10.05-2.87,12.92-7.07l7.89,7.38c-4.3,5.54-10.56,10.25-20.81,10.25-15.07,0-25.21-10.66-25.21-25.93,0-14.56,8.91-26.65,23.47-26.65,16.2,0,23,13,23,25.73,0,1.74-.1,4.1-.1,4.1Zm.61-9.33H516.6c-.11-4.41-2.67-9.84-10.46-9.84C499.58,31.43,496.1,36.45,495.17,41.27Z"
            transform="translate(-0.52 -0.37)"
          />
        </svg>
      </MarketplaceLogoLink>
    </Link>
  );
}

const HeaderContainer = styled.nav`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 90px;
  padding: 0 ${style.vars.space['5']};
  z-index: ${style.vars.zIndex.top};
  background: ${style.colors.white};
  box-shadow: ${style.vars.boxShadow.base};

  ${mediaQueries.mediumMax} {
    /* stylelint-disable-next-line no-duplicate-selectors */
    .${CATEGORY_MENU_CLASSNAME},
      .${USE_CASES_MENU_CLASSNAME},
      .${FOR_VENDORS_MENU_CLASSNAME},
      .${SWITCH_TO_MENU_CLASSNAME} {
      display: none;
    }

    .${MOBILE_MENU_CLASSNAME} {
      display: inline-block;
    }
  }

  ${mediaQueries.largeMin} {
    .${MOBILE_MENU_CLASSNAME} {
      display: none;
    }
  }

  .${CATEGORY_MENU_CLASSNAME} {
    ul {
      width: 720px;
    }
  }

  .${USE_CASES_MENU_CLASSNAME}, .${FOR_VENDORS_MENU_CLASSNAME} {
    ul {
      width: 492px;
    }
  }

  .${SWITCH_TO_MENU_CLASSNAME} {
    ul {
      width: 200px;
      position: absolute;
      right: 0;
      padding: ${style.vars.space['4']};
    }

    hr {
      margin: 0 -${style.vars.space['4']};
      margin-top: ${style.vars.space['2']};
      margin-bottom: ${style.vars.space['4']};
      border-top: 1px solid ${style.colors.grey.primary};
    }

    h6 {
      margin: 0;
    }
  }
`;

const HeaderLeftSection = styled.div`
  display: flex;
  align-items: center;
`;

interface Props {
  categoryItems: NavigationItem[];
  useCaseItems: NavigationItem[];
  vendorItems: NavigationItem[];
  switchToItems: NavigationItem[];
}

const Header = (props: Props) => {
  return (
    <HeaderContainer>
      <HeaderLeftSection>
        <DOLogoNav />
        <MarketplaceLogoNav />

        <NavigationFlyoutMenu
          columns={2}
          title={copy.navigation.categories}
          items={props.categoryItems}
          className={CATEGORY_MENU_CLASSNAME}
        />

        <NavigationFlyoutMenu
          title={copy.navigation.useCases}
          items={props.useCaseItems}
          className={USE_CASES_MENU_CLASSNAME}
        />

        <NavigationFlyoutMenu
          title={copy.navigation.forVendors}
          items={props.vendorItems}
          className={FOR_VENDORS_MENU_CLASSNAME}
        />
      </HeaderLeftSection>

      <NavigationFlyoutMenu
        title={copy.navigation.switchTo}
        items={props.switchToItems}
        className={SWITCH_TO_MENU_CLASSNAME}
        dividerIndex={props.switchToItems.length - 2}
        dividerElement={
          <>
            <hr />
            <Heading level="h6">{copy.navigation.forVendors}</Heading>
          </>
        }
      />

      <MobileMenu
        className={MOBILE_MENU_CLASSNAME}
        items={[
          {
            title: copy.navigation.categories,
            children: props.categoryItems
          },
          {
            title: copy.navigation.useCases,
            children: props.useCaseItems
          },
          {
            title: copy.navigation.forVendors,
            children: props.vendorItems
          },
          {
            title: copy.navigation.switchTo,
            children: props.switchToItems
          }
        ]}
      />
    </HeaderContainer>
  );
};

export default Header;
